import { Box, Button, CircularProgress, styled } from "@mui/material";
import { formatCurrency, promoPriceToSRP } from "common";
import BazarCard from "components/BazarCard";
import LazyImage from "components/LazyImage";
import { H6, Small, Span } from "components/Typography";
import Link from "next/link";
import React, {  useState } from "react";
import { FlexBox } from "components/flex-box";
import _ from "lodash";
import { PcPackageData } from "modules/pc-package/types";

const StyledBazarCard = styled(BazarCard)(() => ({
  height: "100%",
  margin: "auto",
  display: "flex",
  overflow: "hidden",
  borderRadius: "8px",
  position: "relative",
  flexDirection: "column",
  justifyContent: "space-between",
  transition: "all 250ms ease-in-out",
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
  textAlign: "center",
  position: "relative",
  display: "inline-block",
  [theme.breakpoints.down("sm")]: { display: "block" },
}));

const ContentWrapper = styled(Box)(() => ({
  padding: "1rem",
  "& .title, & .categories": {
    overflow: "hidden",
    whiteSpace: "normal",
    textOverflow: "ellipsis",
  },
}));

// ========================================================
type Props = {
  hoverEffect?: boolean;
  pcPackageData?: PcPackageData;
  isLoading?:boolean;
};
// ========================================================

// TODO: Just create a common prop maybe 'Product'
const ProductCard1: React.FC<Props> = ({
  hoverEffect = true,
  pcPackageData,
}) => {
  const[isLoading,setIsLoading]=useState<boolean>(false);
    const loading=()=>{
      setIsLoading(true)
    }
  return (
    <StyledBazarCard hoverEffect={hoverEffect}>
      <ImageWrapper>
        <Box
          sx={{
            marginTop: "16px",
            paddingX: "16px",
            alignItems: "center",
          }}
        >
          <Link
            href={{ pathname: `/pc-package/details/${pcPackageData?.uuid}` }}
          >
            <a>
              <LazyImage
              onClick={loading}
                src={
                  pcPackageData?.cover ||
                  "/assets/images/kachi/kachi-crying.png"
                }
                width={0}
                height={0}
                layout="responsive"
                objectFit="contain"
                alt={pcPackageData?.title}
              />
            </a>
          </Link>
        </Box>
      </ImageWrapper>

      <ContentWrapper>
        <Link href={{ pathname: `/pc-package/details/${pcPackageData?.uuid}` }}>
          <a>
            <FlexBox onClick={loading}>
              <Box flex="1 1 0" minWidth="0px" mr={1}>
                <H6
                  title={pcPackageData?.title}
                  fontWeight="900"
                  className="title"
                  color="text.secondary"
                  sx={{
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    textAlign: "center",
                  }}
                >
                  {pcPackageData?.title}
                </H6>
                <FlexBox flexDirection="column" mt={0.5}>
                  <Box
                    fontSize="24px"
                    fontWeight="600"
                    color="primary.main"
                    margin={"auto"}
                    mb={1}
                  >
                    {formatCurrency(pcPackageData?.amount)||999999}
                  </Box>
                  <Small
                    fontSize="14px"
                    fontWeight="600"
                    color="primary.main"
                    margin={"auto"}
                    mb={1}
                    sx={{ textDecoration: "line-through" }}
                  >
                    {formatCurrency(promoPriceToSRP(pcPackageData?.amount))}
                  </Small>
                  <Span
                    fontSize="16px"
                    color="grey.600"
                    sx={{ textDecoration: "line-through" }}
                  ></Span>
                </FlexBox>
              </Box>
            </FlexBox>
          </a>
        </Link>
        <FlexBox
          className="add-cart"
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
          sx={{ pb: 1 }}
        >
          <Link
            passHref
            href={{ pathname: `/pc-package/details/${pcPackageData?.uuid}` }}
          >
            <Button
              size="small"
              color="secondary"
              variant="contained"
              sx={{ p: 0.5, marginLeft: 0.5 }}
              fullWidth
              onClick={loading}
            >
                 {isLoading?(<CircularProgress color="info" size={20} />):"VIEW PC PACKAGE"}
            </Button>
          </Link>
        </FlexBox>
      </ContentWrapper>
    </StyledBazarCard>
  );
};

export default ProductCard1;
